import { css } from '@emotion/react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import Article from '../../components/Article';
import { SecondaryBtn } from '../../components/Buttons/Secondary';
import { h2 } from '../../components/Headings';
import CommonHero from '../../components/layout/CommonHero';
import Layout from '../../components/layout/Layout';
import Seo from '../../components/layout/Seo';

export default function Types() {
    return (
        <Layout>
            <Seo
                title="types of braces"
                description="There are 5 main types of braces available today: Metal braces; Ceramic braces; Self-ligating braces; Lingual braces · Clear aligners like Invisalign."
            />
            <CommonHero title="types of braces" />
            <Article>
                <h1>types of braces</h1>
                <p>
                    There are many different types of braces to consider when you're planning out
                    your treatment with your orthodontist. Consider the following options as you
                    make plans with your doctor.
                </p>

                <article>
                    <StaticImage
                        src="../../images/services/types/01.jpg"
                        alt="types of braces"
                        loading="eager"
                        width={780}
                        height={780}
                        className="gatsby-img-box"
                    />
                    <div className="textBox">
                        <h2>TRADITIONAL METAL BRACES</h2>
                        <p>
                            The bread-and-butter of orthodontics, these types of braces make up the
                            overwhelming majority of orthodontic treatment plans in place today. The
                            technology has evolved significantly since the onset of orthodontics.
                            And believe it or not, the process is pretty painless. Boiling it down
                            into layman's terms, with traditional braces, we are essentially gluing
                            a tiny metal button (or bracket) to each tooth to correct its position
                            with an archwire that runs through each of them.
                        </p>
                    </div>
                </article>

                <article>
                    <div className="textBox">
                        <h3
                            css={css`
                                ${h2};
                            `}
                        >
                            INVISIBLE BRACES (CLEAR, CERAMIC BRACES)
                        </h3>
                        <p>
                            Some of our patients desire a less noticeable form of orthodontic
                            treatment but still wish to stick to a fixed option for braces. That's
                            where our invisible or clear braces come in. Rather than using metal as
                            the bracket material, these braces are constructed from clear ceramic,
                            allowing you to see right through the bracket to the tooth enamel. We
                            pair these with clear rubber bands and o-rings to ensure a discrete,
                            barely-there approach to traditional fixed braces.
                        </p>
                    </div>

                    <StaticImage
                        src="../../images/services/types/02.jpg"
                        alt="types of braces"
                        width={780}
                        height={780}
                        className="gatsby-img-box"
                    />
                </article>

                <article>
                    <StaticImage
                        src="../../images/services/types/03.jpg"
                        alt="types of braces"
                        width={780}
                        height={780}
                        className="gatsby-img-box"
                    />
                    <div className="textBox">
                        <h4
                            css={css`
                                ${h2};
                            `}
                        >
                            INVISALIGN
                        </h4>
                        <p>
                            Invisalign is an even less noticeable option than ceramic braces. The
                            difference here is that your teeth are moved by using a series of clear,
                            plastic aligner trays that fit over your teeth and hug them tightly.
                            Over the course of treatment, you wear increasingly different trays
                            which gradually move your teeth to their ideal position. The only real
                            downside to these is that you're in control of your treatment. That is,
                            you can take them on and off. If you aren't disciplined enough to stick
                            to the plan, this may not be the very best option for you.
                        </p>

                        <SecondaryBtn
                            as={Link}
                            to="/invisalign/"
                            css={css`
                                margin-top: 32px;
                            `}
                        >
                            LEARN MORE
                        </SecondaryBtn>
                    </div>
                </article>
            </Article>
        </Layout>
    );
}
